import React from "react";
import imageError from "./imageError.svg";
import imageTimeOver from "./TimeOver.svg";
import imageNull from "./imageNull.svg";

const errorImageStyle = {
  width: "auto",
  height: "auto",
  position: "relative",
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)"
};

export default class ImageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: null,
      key: Math.random()
    };
  }
  componentWillReceiveProps(nextPorps) {
    if (this.props.src !== nextPorps.src) {
      this.setState({
        isError: null,
        key: Math.random()
      });
    }
  }
  onError = () => {
    this.setState({ isError: true });
    this.props.onError && this.props.onError();
  };
  render() {
    // errorType: 1: 加载失败， 2：图片过期
    const { isError, key } = this.state;
    let { src, defaultSrc, alt = "", style = {}, hasErrorImageStyle = true, ...props } = this.props;
    let errorType = 1;
    const queryFormat = Utils.queryFormat(src);
    if (queryFormat.expire && queryFormat.expire < BaseStore.user.systemTime) {
      errorType = 2;
    }

    defaultSrc = defaultSrc ? defaultSrc : src ? (errorType === 1 ? imageError : imageTimeOver) : imageNull;
    return (
      <img
        loading="lazy"
        style={Object.assign(
          {},
          style,
          isError || !src ? (hasErrorImageStyle ? errorImageStyle : { width: "auto", height: "auto" }) : {}
        )}
        key={key}
        data-src={src}
        src={isError || !src ? defaultSrc : src}
        {...props}
        alt={alt}
        onError={this.onError}
      />
    );
  }
}
